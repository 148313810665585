import isNull from 'lodash/isNull';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  TIME_ZONE,
  formatDate,
  formatUtc,
  isFalsyString,
  sentenceCase,
  titleCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS, INVOICING_PERMISSIONS } from '@domains/Invoicing/const/permissions';
import { INVOICE_STATUS } from '@domains/Invoicing/const/INVOICE_STATUS';
import { INVOICE_TYPES } from '@domains/Invoicing/const/INVOICE_TYPES';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import CRM_ROUTES from '@domains/CRM/router/CRMRouterMap';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const userCell = {
  attributeName: 'customer_name',
  title: 'User / Company',
  displayPriority: 1,
  columnRatio: 2,
  minWidth: 180,
  type: RECORD_TYPES.component,
  component: PermissionLink,
  props: invoice => {
    const to = !isNull(invoice.user_uuid)
      ? { name: CRM_ROUTES.users.detail.index, params: { userUuid: invoice.user_uuid } }
      : null;
    const href = invoice.user_uuid ? null : companyProfileUrl(invoice.company_uuid);
    const target = invoice.user_uuid ? null : '_blank';
    const linkPermissions = [invoice.user_uuid ? CRM_PERMISSIONS.viewCrmUsers : CRM_PERMISSIONS.viewCrmCompanies];
    return {
      classLink: 'emobg-link-primary emobg-body-2',
      to,
      href,
      target,
      linkPermissions,
      text: `${titleCase(invoice.customer_name)}`,
    };
  },
};

const getBookingIdComponentProps = invoiceData => invoiceData.serie_type === INVOICE_TYPES.migration
  ? { text: 'Legacy' }
  : {
    classLink: 'emobg-link-primary emobg-body-2',
    to: !isFalsyString(invoiceData.booking_uuid) ? {
      name: carsharingRoutes.bookings.detail.index,
      params: {
        bookingUuid: invoiceData.booking_uuid,
      },
    } : null,
    linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
    text: invoiceData.cs_booking_fk ? `#${invoiceData.cs_booking_fk}` : FALLBACK_MESSAGE.dash,
  };

const getPaymentComponentProps = invoiceData => invoiceData.serie_type === INVOICE_TYPES.proforma && invoiceData.status === INVOICE_STATUS.closed
  ? {
    text: FALLBACK_MESSAGE.notAvailable,
    color: '',
  }
  : {
    text: invoiceData.is_pending_to_pay ? 'Unpaid' : 'Paid',
    color: invoiceData.is_pending_to_pay ? COLORS.danger : COLORS.success,
  };

export function contentCells(isUserVisible = true) {
  const cells = [
    {
      title: 'Number',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        target: '_blank',
        href: `/invoicing/invoices/${result.uuid}/summary/details`,
        linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        text: `${result.serie}-${result.number}`,
      }),
    },
    {
      title: 'Date',
      type: RECORD_TYPES.template,
      template: result => result.serie_type === INVOICE_TYPES.migration
        ? `Before ${formatDate(result.date_ts, DATETIME_FORMATS.LongMonthYear)}`
        : formatUtc(result.date_ts, DATE_FORMAT.defaultExtended),
      displayPriority: 1,
      minWidth: 180,
      columnRatio: 1,
    },
    {
      title: 'Booking ID',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 120,
      isCollapseHidden: true,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => getBookingIdComponentProps(result),
    },
    {
      attributeName: 'status',
      title: 'Invoice status',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 130,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: status === 'open' ? COLORS.warning : GRAYSCALE.ground,
      }),
    },
    {
      title: 'Sent',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 90,
      transformResult: result => (result.sent ? 'Yes' : 'No'),
    },
    {
      attributeName: 'operator_city_name',
      title: 'City',
      displayPriority: 2,
      minWidth: 140,
      displayOrder: 1,
      columnRatio: 1,
    },
    {
      attributeName: 'gross_amount_formatted',
      title: 'Amount',
      displayPriority: 2,
      minWidth: 110,
      columnRatio: 1,
    },
    {
      title: 'Pending amount',
      displayPriority: 2,
      columnRatio: 1,
      minWidth: 150,
      transformResult: result => (result.show_pending_amount ? result.pending_amount_formatted : FALLBACK_MESSAGE.dash),
    },
    {
      title: 'Payment status',
      attributeName: 'is_pending_to_pay',
      minWidth: 140,
      displayPriority: 2,
      columnRatio: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: result => getPaymentComponentProps(result),
    },
  ];

  if (isUserVisible) {
    cells.splice(1, 0, userCell);
  }

  return cells;
}

export function invoiceFacets(operatorTimezone = TIME_ZONE.default) {
  return [
    {
      type: 'rangeDateTimePicker',
      props: {
        title: 'Date',
        attributeName: 'date_ts',
        timezone: operatorTimezone,
        labels: {
          from: 'Start date',
          to: 'End date',
          removeButton: 'Clear dates',
        },

      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'City',
        attributeName: 'operator_city_name',
        transformValue: sentenceCase,
        limit: 5,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Serie',
        attributeName: 'serie',
        limit: 5,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Invoice status',
        attributeName: 'status',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Payment status',
        attributeName: 'is_pending_to_pay',
        transformValue: value => (value === 'true' ? 'Unpaid' : 'Paid'),
      },
    },
    {
      type: 'rangeSlider',
      props: {
        title: 'Amount',
        attributeName: 'gross_amount',
        from: value => value,
      },
    },
    {
      type: 'rangeSlider',
      props: {
        title: 'Pending amount',
        attributeName: 'pending_amount',
        from: value => value,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Sent',
        attributeName: 'sent',
        transformValue: value => (value === 'true' ? 'Yes' : 'No'),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Tags',
        attributeName: 'tags',
        transformValue: sentenceCase,
      },
    },
  ];
}

export const exportColumns = [
  { attributeName: 'id', title: 'Id' },
  { attributeName: 'serie', title: 'Serie' },
  { attributeName: 'number', title: 'Number' },
  { attributeName: 'customer_name', title: 'User/Company' },
  { attributeName: 'operator_city_name', title: 'City' },
  {
    attributeName: 'date_ts',
    title: 'Date',
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended),
  },
  { attributeName: 'gross_amount_formatted', title: 'Amount' },
  { attributeName: 'pending_amount_formatted', title: 'Pending Amount' },
  { attributeName: 'status', title: 'Invoice Status' },
  {
    attributeName: 'is_pending_to_pay',
    title: 'Payment Status',
    transformValue: value => (value ? 'Unpaid' : 'Paid'),
  },
  {
    attributeName: 'sent',
    title: 'Sent',
    transformValue: value => (value ? 'Yes' : 'No'),
  },
  { attributeName: 'cs_booking_fk', title: 'Booking ID' },
];
